import React from 'react';
import { Link } from 'react-router-dom';

// import you from '../../assets/icons/youtube.png'
// import insta from '../../assets/icons/insta.png'
// import face from '../../assets/icons/face.png'
// import associate from '../../assets/images/association.png'
// import asso from '../../assets/images/asso.png'
// import tweet from '../../assets/icons/twitter.png'
// import ioni from '../../assets/icons/email.png'
// import './footer.css'
// import FooterNav from '../MobileNav/footerNav';
// import logo from '../../assets/ssezibwalogo.png';


class Title extends React.Component {

    render() {

        return (


            <div className="row">
                <div className="col-sm-12 mb-4 mb-xl-0">

                    <h4 className="font-weight-bold text-dark">{this.props.title}</h4>
                    {/* <p className="font-weight-normal mb-2 text-muted">{this.props.description}</p> */}

                    {this.props.previousPageTitle &&
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={this.props.back}>{this.props.previousPageTitle}</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{this.props.title}</li>
                        </ol>
                    }

                </div>
            </div>


        )
    }
}

export default Title;
