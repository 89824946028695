import React from 'react';
import NavBar from '../shared/navbar/NavBar';
import Footer from '../shared/footer/Footer';
import SideBar from '../shared/sidebar/SideBar';
import Title from '../shared/Title';
import DashCard from '../components/dashboard/cards/DashCard';
import BarChart from '../components/dashboard/charts/BarChart';
import RecentActivity from '../components/dashboard/RecentActivity';
import RecentPayments from '../components/dashboard/RecentPayments';
import Ratings from '../components/dashboard/Rating';
import ajaxPackages from '../utils/remote/ajaxPackages';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';

// import Banner from '../../components/Home/banner/banner';
// import Header from '../../shared/header/Header';
// import Description from '../../components/Home/description/description';
// import Footer from '../../shared/footer/Footer';
// import Cards from '../../components/Home/cards/cards';
// import Testimony from '../../components/Home/testimonials/testimonials';
// import Testimonial from '../../components/Home/testimonials/Testimonial';
// import Contacts from '../../shared/contact/contact';

class Operations extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    countActivePackages: '...',
    // countClampedVehicles: '...',
    // countTowedVehicles: '...',
    // countAvailableParking: '...'
  }

  componentDidMount() {
    this.countActivePackages();
    // this.countClampedVehicles();
    // this.countTowedVehicles();
    // this.countAvailableParking();

    this.timerCountPackages = setTimeout(this.funcSessionCount = async () => {
      await this.countActivePackages();
      this.timerCountPackages = setTimeout(this.funcSessionCount, dictionary._waitTime); // (*)
    }, dictionary._waitTime)

    // this.timerCountClampedVehicles = setTimeout(this.funcClampedVehicleCount = async () => {
    //   await this.countClampedVehicles();
    //   this.timerCountClampedVehicles = setTimeout(this.funcClampedVehicleCount, dictionary._waitTime); // (*)
    // }, dictionary._waitTime)

    // this.timerCountTowedVehicles = setTimeout(this.funcTowedVehicleCount = async () => {
    //   await this.countTowedVehicles();
    //   this.timerCountTowedVehicles = setTimeout(this.funcTowedVehicleCount, dictionary._waitTime); // (*)
    // }, dictionary._waitTime)

    // this.timerCountAvailableParking = setTimeout(this.funcAvailableParking = async () => {
    //   await this.countAvailableParking()
    //   this.timerCountAvailableParking = setTimeout(this.funcAvailableParking, dictionary._waitTime); // (*)
    // }, dictionary._waitTime)
  }

  componentWillUnmount() {
    clearTimeout(this.timerCountPackages)
    // clearTimeout(this.timerCountClampedVehicles)
    // clearTimeout(this.timerCountTowedVehicles)
    // clearTimeout(this.timerCountAvailableParking)
  }


  countActivePackages = async () => {

    const server_response = await ajaxPackages.countActivePackages();
    if (server_response.status === "OK") {
      this.setState({
        countActivePackages: server_response.details.content.total_c
      })
    }
  }

  // countClampedVehicles = async () => {

  //   const server_response = await ajax.countClampedVehicles(this.state.userId);
  //   if (server_response.status === "OK") {
  //     this.setState({
  //       countClampedVehicles: server_response.details.content.total_c
  //     })
  //   }
  // }

  // countTowedVehicles = async () => {

  //   const server_response = await ajax.countTowedVehicles(this.state.userId);
  //   if (server_response.status === "OK") {
  //     this.setState({
  //       countTowedVehicles: server_response.details.content.total_c
  //     })
  //   }
  // }

  ViewAllSessions = () => {
    this.props.history.push('/parking_sessions/sessions')
  }

  ViewAllSpaces = () => {
    this.props.history.push('/parking-space/available-space')
  }

  render() {

    return (

      <div class="container-scroller">
        <NavBar />

        <div class="container-fluid page-body-wrapper">
          <SideBar />

          <div class="main-panel">
            <div class="content-wrapper">
              <Title
                title="Hi, welcome back!"
                description="JANUARY 20, 2022"
                // title="All System Roles"
                previousPageTitle="Dashboard"
                // back="/"
              />

              <div class="row mt-3">
                <div class="col-xl-2 flex-column d-flex grid-margin stretch-card">
                  <div class="row flex-grow">
                    <DashCard
                      name="Customers"
                      percentage="23% increase in conversion"
                      value="43,981"
                    />

                    <DashCard
                      name="Bookings"
                      percentage="6% decrease in earnings"
                      value="55,543"
                    />

                    <DashCard
                      name="Packages"
                      percentage="6% decrease in earnings"
                      value={this.state.countActivePackages}
                      href="/packages"
                    />

                  </div>
                </div>

                <BarChart />

                <RecentActivity />
              </div>

              <div class="row">
                <RecentPayments />

                <Ratings />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }

}

export default Operations;
