import React from 'react'
// import { Link } from 'react-router-dom'


class BarChart extends React.Component {

    render() {
        return (

            <div className="col-xl-7 d-flex grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Website Audience Metrics</h4>
                        <div className="row mb-5">
                            <div className="col-lg-5">
                                <p>Chart information comparing the intake of cash as to the bookings being made in the system</p>
                            </div>
                            <div className="col-lg-7">
                                <div className="chart-legends d-lg-block d-none" id="chart-legends"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <canvas id="web-audience-metrics-satacked" className="mt-3"></canvas>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }

}

export default BarChart
