import React from 'react'
import { Link } from 'react-router-dom'
import TableHeader from '../../shared/TableHeader'


class RecentPayments extends React.Component {

    render() {
        return (

            <div className="col-xl-9 grid-margin-lg-0 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        {/* <h4 className="card-title">Recent Payments</h4> */}
                        <TableHeader
                            title="Recent Payments"
                            subtitle="A representation of the recent payment activity."
                            view="View All"
                            onClick={this.props.onClickViewAll}
                        />

                        <div class="table-responsive mt-3">
                            <table class="table table-hover table-header-bg">
                                <thead>
                                    <tr>
                                        <th>Booking Ref</th>
                                        <th>Client</th>
                                        <th>Package</th>
                                        <th>
                                            Amount (UGX)
                                        </th>
                                        <th>
                                            Deadline
                                        </th>
                                        {/* <th>Sale</th> */}
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>B220202115200R9</td>
                                        <td>Jacob Olanya</td>
                                        <td><Link to="#">Falls Gate Pass
                                        </Link></td>
                                        <td>
                                            50,000
                                        </td>
                                        <td>
                                            May 15, 2015
                                        </td>
                                        {/* <td class="text-success"> 28.76% <i class="mdi mdi-arrow-up"></i></td> */}
                                        <td><label class="badge badge-danger">Cancelled</label></td>
                                    </tr>
                                    <tr>
                                        <td>B220202115200R9</td>
                                        <td>Messsy Lambert</td>
                                        <td><Link to="#">Camping</Link></td>
                                        <td>
                                            50,000
                                        </td>
                                        <td>
                                            July 1, 2015
                                        </td>
                                        {/* <td class="text-danger"> 21.06% <i class="mdi mdi-arrow-down"></i></td> */}
                                        <td><label class="badge badge-warning">Pending</label></td>
                                    </tr>
                                    <tr>
                                        <td>B220202115200R9</td>
                                        <td>John Batulete</td>
                                        <td><Link to="#">Premier</Link></td>
                                        <td>
                                            50,000
                                        </td>
                                        <td>
                                            Apr 12, 2015
                                        </td>
                                        {/* <td class="text-success"> 35.00% <i class="mdi mdi-arrow-up"></i></td> */}
                                        <td><label class="badge badge-warning">Pending</label></td>
                                    </tr>
                                    <tr>
                                        <td>B220202115200R9</td>
                                        <td>Peter Coach</td>
                                        <td><Link to="#">Events and gatherings</Link></td>
                                        <td>
                                            50,000
                                        </td>
                                        <td>
                                            May 15, 2015
                                        </td>
                                        {/* <td class="text-danger"> 82.00% <i class="mdi mdi-arrow-down"></i></td> */}
                                        <td><label class="badge badge-success">Paid</label></td>
                                    </tr>
                                    <tr>
                                        <td>B220202115200R9</td>
                                        <td>Dave Kresinski</td>
                                        <td><Link to="#">Photography</Link></td>
                                        <td>
                                            50,000
                                        </td>
                                        <td>
                                            May 03, 2015
                                        </td>
                                        {/* <td class="text-success"> 98.05% <i class="mdi mdi-arrow-up"></i></td> */}
                                        <td><label class="badge badge-warning">Pending</label></td>
                                    </tr>
                                    <tr>
                                        <td>B220202115200R9</td>
                                        <td>Peter Coach</td>
                                        <td><Link to="#">Events and gatherings</Link></td>
                                        <td>
                                            50,000
                                        </td>
                                        <td>
                                            April 05, 2015
                                        </td>
                                        {/* <td class="text-danger"> 82.00% <i class="mdi mdi-arrow-down"></i></td> */}
                                        <td><label class="badge badge-success">Paid</label></td>
                                    </tr>
                                    <tr>
                                        <td>B220202115200R9</td>
                                        <td>Dave Kresinski</td>
                                        <td><Link to="#">Falls Gate pass</Link></td>
                                        <td>
                                            50,000
                                        </td>
                                        <td>
                                            June 16, 2015
                                        </td>
                                        {/* <td class="text-success"> 98.05% <i class="mdi mdi-arrow-up"></i></td> */}
                                        <td><label class="badge badge-warning">Pending</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="table-responsive mt-3">
                            <table className="table table-header-bg">
                                <thead>
                                    <tr>
                                        <th>
                                            Country
                                        </th>
                                        <th>
                                            Revenue
                                        </th>
                                        <th>
                                            Vs Last Month
                                        </th>
                                        <th>
                                            Goal Reached
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <i className="flag-icon flag-icon-us mr-2" title="us" id="us"></i> United States
                                        </td>
                                        <td>
                                            $911,200
                                        </td>
                                        <td>
                                            <div className="text-success"><i className="icon-arrow-up mr-2"></i>+60%</div>
                                        </td>
                                        <td>
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <div className="progress">
                                                        <div className="progress-bar bg-info" role="progressbar" style={{width: "25%"}}
                                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    25%
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <i className="flag-icon flag-icon-at mr-2" title="us" id="at"></i> Austria
                                        </td>
                                        <td>
                                            $821,600
                                        </td>
                                        <td>
                                            <div className="text-danger"><i className="icon-arrow-down mr-2"></i>-40%</div>
                                        </td>
                                        <td>
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <div className="progress">
                                                        <div className="progress-bar bg-info" role="progressbar" style={{width: "50%"}}
                                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    50%
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i className="flag-icon flag-icon-fr mr-2" title="us" id="fr"></i> France
                                        </td>
                                        <td>
                                            $323,700
                                        </td>
                                        <td>
                                            <div className="text-success"><i className="icon-arrow-up mr-2"></i>+40%</div>
                                        </td>
                                        <td>
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <div className="progress">
                                                        <div className="progress-bar bg-info" role="progressbar" style={{width: "10%"}}
                                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    10%
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-1">
                                            <i className="flag-icon flag-icon-de mr-2" title="us" id="de"></i> Germany
                                        </td>
                                        <td>
                                            $833,205
                                        </td>
                                        <td>
                                            <div className="text-danger"><i className="icon-arrow-down mr-2"></i>-80%</div>
                                        </td>
                                        <td>
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <div className="progress">
                                                        <div className="progress-bar bg-info" role="progressbar" style={{width: "70%"}}
                                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    70%
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pb-0">
                                            <i className="flag-icon flag-icon-ae mr-2" title="ae" id="ae"></i> united arab emirates
                                        </td>
                                        <td className="pb-0">
                                            $232,243
                                        </td>
                                        <td className="pb-0">
                                            <div className="text-success"><i className="icon-arrow-up mr-2"></i>+80%</div>
                                        </td>
                                        <td className="pb-0">
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <div className="progress">
                                                        <div className="progress-bar bg-info" role="progressbar" style={{width: "60%"}}
                                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    0%
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div> */}
                    </div>
                </div>
            </div>

        )
    }

}

export default RecentPayments
