import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('ssezibwa@user');

const apiHost = dictionary.apiHost

export default {

    async loginUser(username, password) {

        let data = {
            "username": username,
            "password": password
        }

        // console.log(data)

        try {
            let response = await fetch(apiHost + 'user/login',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async userInfo(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'user/profile',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async deactivateUser(user_id) {

        let data = {
            // "role_id": role_id,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user.deactivate',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getUserByUsername(username) {

        let data = {
            // "user_id": user_id,
            "username": username
        }

        try {
            let response = await fetch(apiHost + 'user/search/username',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

}
