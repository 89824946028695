import React from 'react'

class PageNav extends React.Component {

    state = {
        currentPage: this.props.currentPage,
        pageList: this.props.pageList,
        totalPages: this.props.totalPages
    }

    render() {
        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className={`page-item ${this.state.currentPage === 1 && "disabled"}`}>
                        <a
                            className="page-link"
                            tabIndex="-1"
                            aria-disabled={this.state.currentPage === 1 ? false : true}
                            onClick={this.props.onClickPrevious}
                        >Previous</a>
                    </li>

                    {this.state.pageList.map((item, key) =>
                        <li className={`page-item ${this.state.currentPage === item && "active"}`} key={key}>
                            <a
                                className="page-link"
                                onClick={() => this.props.onClickPage(item)}>{item}</a>
                        </li>)}

                    <li className={`page-item ${this.state.currentPage === this.state.totalPages && "disabled"}`}>
                        <a
                            className="page-link"
                            onClick={this.props.onClickNext}>Next</a>
                    </li>
                </ul>
            </nav>
        )
    }

}

export default PageNav