import React from 'react';
import NavBar from '../shared/navbar/NavBar';
import Footer from '../shared/footer/Footer';
import SideBar from '../shared/sidebar/SideBar';
import Title from '../shared/Title';
// import DashCard from '../components/dashboard/cards/DashCard';
// import BarChart from '../components/dashboard/charts/BarChart';
// import RecentActivity from '../components/dashboard/RecentActivity';
// import RecentPayments from '../components/dashboard/RecentPayments';
import Ratings from '../components/dashboard/Rating';
import ajaxPackages from '../utils/remote/ajaxPackages';
// import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import PageNav from '../shared/PageNav';
import TableHeader from '../shared/TableHeader';
import { Link } from 'react-router-dom';
import ActivitySpinner from '../shared/ActivitySpinner';
// import PageHeader from '../shared/PageHeader';

// import Banner from '../../components/Home/banner/banner';
// import Header from '../../shared/header/Header';
// import Description from '../../components/Home/description/description';
// import Footer from '../../shared/footer/Footer';
// import Cards from '../../components/Home/cards/cards';
// import Testimony from '../../components/Home/testimonials/testimonials';
// import Testimonial from '../../components/Home/testimonials/Testimonial';
// import Contacts from '../../shared/contact/contact';

class Packages extends React.Component {

    state = {
        userId: functions.sessionGuard(),

        packageList: false,
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        conductors: false,
        status: '',
        locationPIN: false,
        warnSessionEnd: false,
        dialogue: "",
        noOfRecords: 8,

        metaData: false,
        currentPage: 1,
        pagination: false
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                status: this.props.location.state.status
            }, () => this.listAllPackages())
        } else {
            this.listAllPackages();
        }
        // this.getAllStartedBy();
        // this.setState({
        //     menuOptions: {
        //         "addSession": () => this.onClickStartSession()
        //     }
        // })

    }


    listPackages = async () => {
        const server_response = await ajaxPackages.listPackages(this.state.status, this.state.currentPage);
        if (server_response.status === "OK") {
            this.setState({
                packageList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, () => this.paginate())
        } else {
            this.setState({
                packageList: "404",
                metaData: false
            })
        }
    }


    listAllPackages = async () => {
        if (this.state.filter) {
            const filter = this.state.filter;
            var server_response = await ajaxPackages.filterPackages(
                filter.dateFrom,
                filter.dateTo,
                // filter.selectedValue,
                filter.keyword,
                this.state.currentPage,
                this.state.status
            );


        } else {
            var server_response = await ajaxPackages.listPackages(this.state.status, this.state.currentPage);
        }
        
        if (server_response.status === "OK") {
            this.setState({
                packageList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, () => this.paginate())

        } else {
            this.setState({
                packageList: "404",
                metaData: false
            })
        }
    }

    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            packageList: false
        }, () => this.listPackages())
    }

    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                packageList: false

            }, () => {
                this.listPackages();
            })
        }
    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1,
                packageList: false
            }, () => {
                this.listPackages();
            })
        }
    }

    onFilter = async (filter) => {
        this.setState({
            filter: false,
            packageList: false,
            metaData: false
        }, () => {
            this.setState({ filter: filter }, () => this.listAllPackages())
        })
    }

    resetFilter = () => {
        this.setState({
            filter: false,
            packageList: false
        }, () => this.listAllPackages())
    }


    onChangeStatus = (status) => {
        this.setState({
            status: status,
            packageList: false,
            metaData: false,
            currentPage: 1
        }, () => this.listAllPackages())
    }

    paginate = () => {
        if (this.state.metaData) {
            this.setState({
                pagination: false
            }, () => {

                this.setState({
                    pagination: <PageNav
                        totalPages={this.state.metaData.total_pages}
                        pageList={this.state.metaData.list_of_pages}
                        currentPage={this.state.currentPage}
                        onClickNext={this.onClickNext}
                        onClickPage={this.onClickPage}
                        onClickPrevious={this.onClickPrevious}
                    />
                })

            })

        }
    }


    render() {

        const packageList = this.state.packageList;
        // const filter = this.state.filter

        return (

            <div class="container-scroller">
                <NavBar />

                <div class="container-fluid page-body-wrapper">
                    <SideBar />

                    <div class="main-panel">
                        <div class="content-wrapper">

                            <Title
                                title="All System Packages!"
                                description="JANUARY 20, 2022"
                                // title="All System Roles"
                                previousPageTitle="Dashboard"
                                back="/"
                            />

                            <div class="row mt-3">
                                <div className="col-xl-9 grid-margin-lg-0 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">

                                            <TableHeader
                                                title="All system packages"
                                                subtitle="A tabular representation of the packages."
                                            />

                                            <div class="table-responsive mt-3">
                                                <table class="table table-hover table-header-bg">
                                                    <thead>
                                                        <tr>
                                                            <th>Package ID</th>
                                                            <th>Image</th>
                                                            <th>Package Name</th>
                                                            <th>Package Description</th>
                                                            <th>Created At</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {packageList && packageList !== "404" &&
                                                            packageList.map((item, key) =>
                                                                <tr key={key}>
                                                                    {/* <td>{item.id}</td> */}
                                                                    <td>
                                                                        <Link
                                                                            to="#"
                                                                            onClick={() => this.viewPackageInfo(item)}>
                                                                            {item.package_id_c}
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <div className="main-img-package">
                                                                            <img alt="avatar" src={item.package_photo.file_path} />
                                                                        </div>
                                                                    </td>
                                                                    <td>{item.package_name}</td>
                                                                    <td className="cell_size">{item.package_description}</td>
                                                                    <td>{item.created_at.date}<br /><small className="tx-12 tx-gray-500">{item.created_at.time}</small></td>
                                                                    <td>
                                                                        {/* <label class="badge badge-danger">Pending</label> */}
                                                                        {item.status === "1" && <span className="badge badge-success">Active</span>}
                                                                        {item.status === "0" && <span className="badge badge-secondary">Ended</span>}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {/* <tr>
                                                            <td>Messsy</td>
                                                            <td>Flash</td>
                                                            <td class="text-danger"> 21.06% <i class="mdi mdi-arrow-down"></i></td>
                                                            <td><label class="badge badge-warning">In progress</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td>John</td>
                                                            <td>Premier</td>
                                                            <td class="text-success"> 35.00% <i class="mdi mdi-arrow-up"></i></td>
                                                            <td><label class="badge badge-info">Fixed</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Peter</td>
                                                            <td>After effects</td>
                                                            <td class="text-danger"> 82.00% <i class="mdi mdi-arrow-down"></i></td>
                                                            <td><label class="badge badge-success">Completed</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dave</td>
                                                            <td>53275535</td>
                                                            <td class="text-success"> 98.05% <i class="mdi mdi-arrow-up"></i></td>
                                                            <td><label class="badge badge-warning">In progress</label></td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>

                                                {this.state.metaData && this.state.pagination}

                                                <div className="pagination-center">
                                                    {!packageList && <ActivitySpinner />}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <Ratings />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }

}

export default Packages;
