import React from 'react'
import TableHeader from '../../shared/TableHeader'
// import { Link } from 'react-router-dom'


class RecentActivity extends React.Component {

  render() {
    return (

      <div className="col-xl-3 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">

            <TableHeader
              title="Recent Activity"
              subtitle="A representation of the recent activity."
              view="View All"
              onClick={this.props.onClickViewAll}
            />

            <div className="row">
              <div className="col-sm-12">
                <div className="text-dark">
                  <div className="d-flex pb-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>Deposit has updated to Paid</div>
                      <div className="text-muted font-weight-normal mt-1">32 Minutes Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">$325</h6>
                    </div>
                  </div>
                  <div className="d-flex pb-3 pt-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>Your Withdrawal Proceeded</div>
                      <div className="text-muted font-weight-normal mt-1">45 Minutes Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">$4987</h6>
                    </div>
                  </div>
                  <div className="d-flex pb-3 pt-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>Deposit has updated to Paid </div>
                      <div className="text-muted font-weight-normal mt-1">1 Days Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">$5391</h6>
                    </div>
                  </div>
                  <div className="d-flex pb-3 pt-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>Deposit has updated to Paid </div>
                      <div className="text-muted font-weight-normal mt-1">1 Days Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">$5391</h6>
                    </div>
                  </div>
                  <div className="d-flex pb-3 pt-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>Deposit has updated to Paid </div>
                      <div className="text-muted font-weight-normal mt-1">1 Days Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">$5391</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }

}

export default RecentActivity
