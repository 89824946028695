import React from 'react';
import { TailSpin } from 'react-loader-spinner';

class ActivitySpinner extends React.Component {


    render() {

        return (
            <>
                {/* <div class="loader"></div> */}
                <div class="lds-facebook">
                    <div>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                </div>
            </>
        );


    }
}

export default ActivitySpinner
