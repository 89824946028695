import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('ssezibwa@user');
const apiHost = dictionary.apiHost

export default {

    async countActivePackages() {

        try {
            let response = await fetch(apiHost + 'package.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async filterPackages(
        createdFrom,
        createdTo,
        // startedBy,
        keyword,
        page,
        status
    ) {

        let data = {
            // "conductor_id": startedBy,
            "created_from": createdFrom,
            "created_to": createdTo,
            "keyword": keyword,
            "page": page,
            "status": status
        }
        try {
            let response = await fetch(apiHost + 'session/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listPackages(status, page) {

        let data = {
            "status": status,
            "page": page
        }


        try {
            let response = await fetch(apiHost + 'package/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

}
