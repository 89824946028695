import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';
import Login from '../pages/auth/Login';
import Operations from '../pages/Operations';
import Packages from '../pages/Packages';
import Notfoundpage from '../shared/Notfound';
import functions from '../utils/functions';
// import AboutView from '../containers/About/aboutview';
// import GalleryView from '../containers/Gallery/galleryview';

// import DetailsView from '../containers/Details/detailsview';
// import DetailsView1 from '../containers/Details/detailsview1';
// import DetailsView2 from '../containers/Details/detailsview2';

// import FacilitiesView from '../containers/Facilities/facilitiesview';

// import ActivitiesView from '../containers/Activities/activitiesview';
// import BookingView from '../containers/Activities/activitiesview';
// import AccommodationView from '../containers/Accommodation/accommodationview';
// import HomeView from '../containers/Home/homeview';
// import Notfoundpage from '../shared/Not';
// import Footer from '../shared/footer/Footer';

class Routes extends React.Component {

    state = {
        screen: "Login",
        loggedIn: false,
        accessInfo: functions.permissionGuard()
    }

    componentDidMount() {
        this.checkLogin();
    }

    checkLogin() {
        if (!localStorage.getItem("ssezibwa@user")) {
            this.setState({
                loggedIn: false
            })
        } else {
            this.setState({
                loggedIn: true
            })
        }
    }

    render() {

        return (

            <Router>
                <Switch>
                    {this.state.loggedIn === false &&
                        <Route exact path="/" component={Login} />
                    }
                    {this.state.loggedIn === true &&
                        <>
                            <Route exact path="/" component={Operations} />
                            <Route exact path="/packages" component={Packages} />
                            {/* <Route path="/about" exact component={AboutView} />
                                <Route path="/book_an_experience" exact component={AccommodationView} />
                                <Route path="/gallery" exact component={GalleryView} />
                                <Route path="/details" exact component={DetailsView} />
                                <Route path="/details1" exact component={DetailsView1} />
                                <Route path="/details2" exact component={DetailsView2} />
                                <Route path="/activities" exact component={ActivitiesView} />
                                <Route path="/booking" exact component={BookingView} />
                                <Route path="/facilities" exact component={FacilitiesView} /> */}
                            <Route path="/notfound" exact component={Notfoundpage} />
                        </>
                    }
                </Switch>
            </Router>
        )
    }
}


export default Routes;
